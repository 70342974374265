// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'subject', 'subjectForm', 'messageThread', 'bodyInput', 'noteBodyInput', 'submit', 'submitNote' ]

  connect() {
    this.data.set('originalReplyText', this.bodyInputTarget.value);
    $(this.subjectFormTarget).hide();

    $('#replyTabs a').on('click', function (e) {
      e.preventDefault();
      $(this).tab('show');
    });

    $(this.bodyInputTarget).on('focus', function(e) {
      this.classList.add('editing');
      this.classList.remove('reply-sent');
    });
    $(this.noteBodyInputTarget).on('focus', function(e) {
      this.classList.add('editing');
      this.classList.remove('reply-sent');
    });
  }

  editSubject() {
    $(this.subjectTarget).parent().hide();
    $(this.subjectFormTarget).show();
    $(this.subjectFormTarget).find('input').select();
  }

  stopEditingSubject() {
    $(this.subjectFormTarget).hide();
    $(this.subjectTarget).parent().show();
  }

  onSubjectSuccess() {
    let [data, status, xhr] = event.detail;
    $(this.subjectTarget).text(xhr.response);
    this.stopEditingSubject();
  }

  onKeyDown() {
    if ((event.ctrlKey || event.metaKey) && (event.keyCode == 13 || event.keyCode == 10)) {
      event.preventDefault();
      $(this.submitTarget).click();
    }
  }

  onKeyDownNote() {
    if ((event.ctrlKey || event.metaKey) && (event.keyCode == 13 || event.keyCode == 10)) {
      event.preventDefault();
      $(this.submitNoteTarget).click();
    }
  }

  onPostSuccessPartial() {
    let [data, status, xhr] = event.detail;
    $(this.messageThreadTarget).prepend(xhr.response);
    this.bodyInputTarget.classList.remove('editing');
    this.bodyInputTarget.classList.add('reply-sent');
    this.bodyInputTarget.value = this.data.get('originalReplyText');
  }

  onPostSuccessNote() {
    let [data, status, xhr] = event.detail;
    $(this.messageThreadTarget).prepend(xhr.response);
    this.noteBodyInputTarget.classList.remove('editing');
    this.noteBodyInputTarget.classList.add('reply-sent');
    this.noteBodyInputTarget.value = '';
  }
}
